import React from 'react';

const BibleSelection = ({ bibles, selectedBibleId, handleBibleChange, showSelectBibleVersionHeading = true, showBibleVersionDropdown = true }) => (
  <div style={{
    width: '150px',
  }}>
    {showSelectBibleVersionHeading && (
      <h3>Select a Bible Version</h3>
    )}

    {showBibleVersionDropdown && (
      <select className="dropdown" value={selectedBibleId} onChange={handleBibleChange}>
      {bibles.map(bible => (
        <option key={bible.id} value={bible.id}>{bible.name}</option>
      ))}
    </select>
    )}
  </div>
);

export default BibleSelection;
