import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BibleViewer from './components/bible/BibleViewer';
import ImageViewer from './components/image/ImageViewer';
import Dashboard from './components/dashboard/Dashboard'; 
import MainLayout from './components/layout/MainLayout';
import AISearch from './components/dashboard/apps/AISearch';
import ProtectedRoute from './components/shared/ProtectedRoute';

import netlifyIdentity from 'netlify-identity-widget';

import './App.css';

// Initialize Netlify Identity
netlifyIdentity.init();

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
          <Route path="dashboard/bible-study" 
            element={
              <ProtectedRoute>
            <BibleViewer 
              pageTitle={'Multi-Dimensional Bible Study'}
              showSelectBibleVersionHeading={false}
              showBibleVersionDropdown={true}
              showSelectBookHeader={false} 
              showBookFilterTextbox={true}
              showChapterSelectHeading={false}
              showRecentlyGeneratedImages={true}
            />
            </ProtectedRoute>} 
          />
          <Route path="dashboard/simple-search" element={<AISearch />} />
          <Route path="dashboard/bible-verse-viewer" 
            element={
              <ProtectedRoute>
                <BibleViewer 
                  pageTitle={'Generate Bible Verse Images'}
                  showSelectBibleVersionHeading={false}
                  showBibleVersionDropdown={false}
                  showSelectBookHeader={false} 
                  showBookFilterTextbox={false}
                  showChapterSelectHeading={false}
                />
            </ProtectedRoute>
          }/>
          <Route path="dashboard/image-viewer" element={
            <ProtectedRoute>
              <ImageViewer />
            </ProtectedRoute>
          } />
          <Route path="dashboard/ai-search" element={
            <ProtectedRoute>
              <AISearch />
            </ProtectedRoute>
          } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
