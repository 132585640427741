import React, { useState, useEffect, useRef } from 'react';
import { fetchBibles, fetchBooks, fetchChapters, fetchVerses, fetchVerseById } from '../../api/bibleApi';
import { generateImage } from '../../api/imageGeneratorApi';
import { handle5DChat, handleChatAppendVerses, handleGenerateChapterSummary } from '../../api/chatApi';
import BibleSelection from './BibleSelection';
import BookSelection from './BookSelection';
import ChapterSelection from './ChapterSelection';
import VersesDisplay from './VersesDisplay';
import ImageGenerator from '../image/ImageGenerator';
import RecentImages from '../image/RecentImages';
import Modal from '../shared/Modal';
// import BackToDashboardLink from "../dashboard/BackToDashboardLink";

import './BibleViewer.css';

function BibleViewer({ 
    pageTitle,
    showSelectBibleVersionHeading=true,
    showBibleVersionDropdown=true,
    showSelectBookHeader=true, 
    showBookFilterTextbox=true, 
    showChapterSelectHeading = true,
    showRecentlyGeneratedImages = false }
    ) {
  const [bibles, setBibles] = useState([]);
  const [selectedBibleId, setSelectedBibleId] = useState('de4e12af7f28f599-02');
  const initialBibleId = useRef(selectedBibleId); //Use useRef to store the initial value of selectedBibleId in BibleViewer. This change avoids including selectedBibleId in the useEffect dependency array, ensuring the effect runs only once on mount. This approach resolves the ESLint warning about missing dependencies in useEffect and maintains the intended logic of resetting selectedBibleId to its initial value if present in the fetched data.
  const [books, setBooks] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [verses, setVerses] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState(new Set());
  const [bookFilter, setBookFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [recentImages, setRecentImages] = useState([]);

  const [modalContentType, setModalContentType] = useState('image'); // 'image' or 'text'
  const [modalImage, setModalImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  
  const [is5DDataLoading, setIs5DDataLoading] = useState(false);

  const [searchHistory, setSearchHistory] = useState([]);
  const [apiResponse, setApiResponse] = useState('');

  const [isExpanded, setIsExpanded] = useState([]);

  const [aiSearchTextInput, setAiSearchTextInput] = useState('');
  const [isAISearchboxLoading, setIsAISearchboxLoading] = useState(false);
  const [showAiSearchModal, setShowAiSearchModal] = useState(false);

  const [chapterSummary, setChapterSummary] = useState('');
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  const toggleExpand = (index) => {
    setIsExpanded(prevState => {
      const newExpandedState = [...prevState];
      newExpandedState[index] = !newExpandedState[index];
      return newExpandedState;
    });
  };

  useEffect(() => {
    const loadedImages = JSON.parse(localStorage.getItem('recentImagesBibleViewer')) || [];
    setRecentImages(loadedImages);

    fetchBibles().then(response => {
      setBibles(response.data);
      // Check if the initial Bible ID is present in the fetched data
      // and reset selectedBibleId to its initial value if it is present.
      // We use a ref (initialBibleId) to avoid including selectedBibleId
      // in the dependency array, as this effect should only run on mount.
      if (response.data.some(bible => bible.id === initialBibleId.current)) {
        setSelectedBibleId(initialBibleId.current); // Use initialBibleId.current here
      }
    }).catch(console.error);

    const loadedHistory = JSON.parse(localStorage.getItem('bibleViewerSearchHistory')) || [];
    setSearchHistory(loadedHistory);

    setIsExpanded(new Array(loadedHistory.length).fill(false));
  }, []); //empty dependency array


  useEffect(() => {
    if (selectedBibleId) {
      fetchBooks(selectedBibleId).then(response => {
        setBooks(response.data);
        setSelectedBookId(null);
        setChapters([]);
        setSelectedChapterId(null);
      }).catch(console.error);
    }
  }, [selectedBibleId]);

  useEffect(() => {
    if (selectedBibleId && selectedBookId) {
      fetchChapters(selectedBibleId, selectedBookId).then(response => {
        setChapters(response.data);
        setSelectedChapterId(null);
      }).catch(console.error);
    }
  }, [selectedBibleId, selectedBookId]);

  useEffect(() => {
    if (selectedBibleId && selectedBookId && selectedChapterId) {
      setIsLoading(true);
      fetchVerses(selectedBibleId, selectedChapterId).then(verseIds => {
        setSelectedVerses(new Set());
        const fetchPromises = verseIds.map(verseId => fetchVerseById(selectedBibleId, verseId));
        Promise.all(fetchPromises).then(verseTexts => {
          const versesWithText = verseTexts.map((text, index) => ({
            id: verseIds[index],
            text: text
          }));
          setVerses(versesWithText);
        }).finally(() => {
          setIsLoading(false);
        });
      });
    }
  }, [selectedBibleId, selectedBookId, selectedChapterId]);

  useEffect(() => {
    const fetchChapterSummary = async () => {
      if (selectedChapterId && chapters.length > 0) {
        setIsSummaryLoading(true);
        try {
          const chapter = chapters.find(chapter => chapter.id === selectedChapterId)?.reference;
          const data = { query: chapter };
          const response = await handleGenerateChapterSummary(data);
          const chapterInfo = chapters.find(chapter => chapter.id === selectedChapterId);
          setChapterSummary(chapterInfo ? response.result : 'No summary available');
        } catch (error) {
          console.error('Error fetching chapter summary:', error);
          setChapterSummary('Failed to fetch summary.');
        } finally {
          setIsSummaryLoading(false);
        }
      }
    };
  
    fetchChapterSummary();
  }, [selectedChapterId, chapters]); // Include 'chapters' in the dependencies

  const handleBibleChange = (event) => {
    setSelectedBibleId(event.target.value);
    setBooks([]);
    setChapters([]);
    setSelectedBookId(null);
    setSelectedChapterId(null);
    setVerses([]);
    setSelectedVerses(new Set());
    setIsLoading(false);
  };

  const handleBookFilterChange = (event) => {
    setBookFilter(event.target.value);
  };

  const handleBookClick = (event) => {
    setSelectedBookId(event.target.value);
    setChapters([]);
    setSelectedChapterId(null);
    setVerses([]);
    setSelectedVerses(new Set());
    setIsLoading(false);
  };

  const handleChapterChange = (event) => {
    setSelectedChapterId(event.target.value);
  };

  const handleVerseSelection = (verseId) => {
    setSelectedVerses(prevSelectedVerses => {
      const newSelectedVerses = new Set(prevSelectedVerses);
      if (newSelectedVerses.has(verseId)) {
        newSelectedVerses.delete(verseId);
      } else {
        newSelectedVerses.add(verseId);
      }
      return newSelectedVerses;
    });
  };

  const handleGenerateImage = async () => {
    const selectedTexts = Array.from(selectedVerses).map(verseId => {
      const verse = verses.find(v => v.id === verseId);
      return verse ? verse.text.replace(/^\d+\s*/, '') : ''; // Remove verse number
    });
    const combinedText = selectedTexts.join(' ');
    setIsGeneratingImage(true);
    try {
      const imageUrl = await generateImage(combinedText);
      setGeneratedImageUrl(imageUrl);
      setModalContentType('image');
      setModalImage(imageUrl);
      setShowModal(true);
      updateRecentImages(imageUrl, combinedText);
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const handleGenerate5Dimensions = async () => {
    setIs5DDataLoading(true); // Start loading
    const selectedVersesArray = Array.from(selectedVerses);
    const selectedVersesString = selectedVersesArray.join(',');
    const data = {
      query: selectedVersesString
    };
  
    try {
      const response = await handle5DChat(data);
      if (response.result) {
        // Assuming the response is an object with a property 'result' that holds the desired text
        setApiResponse(response.result);
        setModalContentType('text');
        setShowModal(true);
  
        // Save to LocalStorage
        const newHistory = [...searchHistory, response.result];
        setSearchHistory(newHistory);
        localStorage.setItem('bibleViewerSearchHistory', JSON.stringify(newHistory));
      } else {
        // Handle case where 'result' is not in the response
        console.error('No result found in response');
      }
    } catch (error) {
      console.error('Error fetching 5D chat:', error);
    } finally {
      setIs5DDataLoading(false); // Stop loading
    }
  };
  
  const handleGenericTextSearch = async () => {
    setIsAISearchboxLoading(true);
    const selectedVersesArray = Array.from(selectedVerses);
    const selectedVersesString = selectedVersesArray.join(',');
    const query = `${selectedVersesString}.${aiSearchTextInput}`;

    try {
        
        const response = await handleChatAppendVerses({ query });
        if (response.result) {
          // Assuming the response is an object with a property 'result' that holds the desired text
          setApiResponse(response.result);
          setModalContentType('text');
          setShowModal(true);
    
          // Save to LocalStorage
          const newHistory = [...searchHistory, response.result];
          setSearchHistory(newHistory);
          localStorage.setItem('bibleViewerSearchHistory', JSON.stringify(newHistory));
        } else {
          // Handle case where 'result' is not in the response
          console.error('No result found in response');
        }
    } catch (error) {
        console.error('Error:', error);
        setApiResponse('Failed to fetch response.');
    }
    setIsAISearchboxLoading(false);
};
  

  const updateRecentImages = (newImageUrl, text) => {
    const updatedImages = [{ url: newImageUrl, text }, ...recentImages].slice(0, 5);
    setRecentImages(updatedImages);
    localStorage.setItem('recentImagesBibleViewer', JSON.stringify(updatedImages));
  };

  const openModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const removeImage = (index) => {
    const updatedImages = [...recentImages];
    updatedImages.splice(index, 1);
    setRecentImages(updatedImages);
    localStorage.setItem('recentImagesBibleViewer', JSON.stringify(updatedImages));
  };

  const filteredBooks = books.filter(book => 
    book.name.toLowerCase().includes(bookFilter.toLowerCase())
  );

  const Loader = () => (
    <div className="loader"></div>
  );

  const deleteHistoryEntry = (index) => {
    const updatedHistory = searchHistory.filter((_, i) => i !== index);
    setSearchHistory(updatedHistory);
    localStorage.setItem('bibleViewerSearchHistory', JSON.stringify(updatedHistory));
  
    // Also update the isExpanded array
    const updatedIsExpanded = isExpanded.filter((_, i) => i !== index);
    setIsExpanded(updatedIsExpanded);
  };
  
  return (
    <div className="bible-viewer-container container-flexible-margin">
      {/* <BackToDashboardLink /> */}
      <h2>{pageTitle}</h2>
      

      <div className="selection-row">
        {showBibleVersionDropdown && (
          <BibleSelection 
          bibles={bibles} 
          selectedBibleId={selectedBibleId} 
          handleBibleChange={handleBibleChange} 
          showSelectBibleVersionHeading={showSelectBibleVersionHeading}
          showBibleVersionDropdown={showBibleVersionDropdown}
          />
        )}
        
        <BookSelection 
          bookFilter={bookFilter} 
          handleBookFilterChange={handleBookFilterChange}
          selectedBookId={selectedBookId}
          handleBookClick={handleBookClick}
          filteredBooks={filteredBooks}
          showSelectBookHeader={showSelectBookHeader} 
          showBookFilterTextbox={showBookFilterTextbox}
        />

        <ChapterSelection 
          selectedBookId={selectedBookId}
          selectedChapterId={selectedChapterId}
          handleChapterChange={handleChapterChange}
          chapters={chapters}
          showChapterSelectHeading={showChapterSelectHeading}
        />
      </div>
      

      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div>
          {selectedChapterId && (
            <div>
              <div className="selected-chapter-info">
                {chapters.find(chapter => chapter.id === selectedChapterId)?.reference || 'Unknown'}
              </div>
              {isSummaryLoading ? (
                <div className="loader"></div>
              ) : (
                <div className="chapter-summary">
                  {chapterSummary}
                </div>
              )}
            </div>
          )}
          <VersesDisplay
            verses={verses}
            selectedVerses={selectedVerses}
            handleVerseSelection={handleVerseSelection}
          />
        </div>
      )}


      <ImageGenerator 
        isGeneratingImage={isGeneratingImage}
        generatedImageUrl={generatedImageUrl}
      />

      {showRecentlyGeneratedImages === false && (
        <RecentImages 
        recentImages={recentImages}
        openModal={openModal}
        removeImage={removeImage}
        />
      )}
      
      {selectedVerses.size > 0 && (
        <div className='floating-buttons-container'>
          {showRecentlyGeneratedImages === false && (
            <button className="floating-button" onClick={handleGenerateImage}>
              {isGeneratingImage ? <Loader /> : 'Generate Image'} 
            </button>
          )}

          {showRecentlyGeneratedImages && (
            <div>
              <button className="floating-button" onClick={() => setShowAiSearchModal(true)}>
                Use AI Search
              </button>

              <button className="floating-button" onClick={handleGenerate5Dimensions}>
                View 5 dimensions {is5DDataLoading && <Loader />}
              </button> 
            </div>
          )}
          
        </div>
      )}

      {showAiSearchModal && (
        <div className="ai-search-overlay">
          <div className="ai-search-modal">
            <textarea
              value={aiSearchTextInput}
              onChange={(e) => setAiSearchTextInput(e.target.value)}
              style={{
                width: '80%', 
                height: '100px',
              }}
              placeholder="Enter your query here, e.g., summarize the chapter, create a quiz based on selected verses, generate insightful questions on these verses..."
            />
            <br />
            <button className="floating-button" onClick={handleGenericTextSearch}>
              Search 
            </button>
            <button className="floating-button" onClick={() => setShowAiSearchModal(false)}>
              Close
            </button>
            <br />
            {isAISearchboxLoading && <Loader />}
          </div>
        </div>
      )}



    <Modal 
      showModal={showModal}
      closeModal={closeModal}
      modalImage={modalImage}
      modalContent={modalContentType === 'image' ? modalImage : apiResponse}
      contentType={modalContentType}
    />

    {showRecentlyGeneratedImages && (

    <div className="history-section">
      <h3>Scripture Analysis History</h3>
      {searchHistory.length === 0 ? (
            <div className="no-items-message">
              No history available.
            </div>
          ) : 
      (searchHistory.slice().reverse().map((entry, entryIndex) => (
        <div key={entryIndex} className="history-entry">
          <h4>Entry {searchHistory.length - entryIndex}</h4>
          {isExpanded[searchHistory.length - 1 - entryIndex]
            ? entry.split('\n').map((line, lineIndex) => <p key={lineIndex}>{line}</p>)
            : <p>{entry.substring(0, 100)}...</p> // Truncate text
          }
          <button onClick={() => toggleExpand(searchHistory.length - 1 - entryIndex)}>
            {isExpanded[searchHistory.length - 1 - entryIndex] ? 'Collapse' : 'Expand'}
          </button>
          <button 
            className="delete-history-button" 
            onClick={() => deleteHistoryEntry(searchHistory.length - 1 - entryIndex)}
          >
            Delete
          </button>
        </div>
      ))
    )}
    </div>
    )}

    </div>
  );
}

export default BibleViewer;
