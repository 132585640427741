import React, { useState, useEffect } from 'react';
import { generateImage } from '../../api/imageGeneratorApi';
// import BackToDashboardLink from "../dashboard/BackToDashboardLink";
import './ImageViewer.css';

function ImageViewer() {
  const [inputText, setInputText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recentImages, setRecentImages] = useState([]);
  const [modalImage, setModalImage] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const loadedImages = JSON.parse(localStorage.getItem('recentImages')) || [];
    setRecentImages(loadedImages);
  }, []);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const newImageUrl = await generateImage(inputText);
      setImageUrl(newImageUrl);
      updateRecentImages(newImageUrl, inputText);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
  };

  const updateRecentImages = (newImageUrl, text) => {
    const updatedImages = [{ url: newImageUrl, text }, ...recentImages].slice(0, 5);
    setRecentImages(updatedImages);
    localStorage.setItem('recentImages', JSON.stringify(updatedImages));
  };

  const openModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const removeImage = (index) => {
    const updatedImages = [...recentImages];
    updatedImages.splice(index, 1);
    setRecentImages(updatedImages);
    localStorage.setItem('recentImages', JSON.stringify(updatedImages));
  };

  return (
    <div className="image-viewer-container container-flexible-margin">
      {/* <BackToDashboardLink /> */}
      {/* Header */}
      <div className="header-container">
        <h1 className="header-title">AI Image Generator</h1>
        <p className="header-subtitle">What would you like to see?</p>
      </div>

      <textarea
        value={inputText}
        onChange={handleInputChange}
        placeholder="Add your text here..."
        rows={4}
        className="text-input"
      />
      <button 
        onClick={handleSubmit} 
        className="submit-button"
        disabled={isLoading || inputText.trim() === ''}
      >
        Generate Image
      </button>
      <hr />

      {isLoading ? (
        <div className="loader"></div>
      ) : imageUrl ? (
        <img src={imageUrl} alt="Generated" />
      ) : null}

      <div className="recent-images">
        <h2>Recently Generated Images</h2>
        {recentImages.length === 0 ? (
            <div className="no-items-message">
              No images have been generated yet.
            </div>
          ) : 
          ( recentImages.map((item, index) => (
            <div key={index} className="thumbnail">
              <img src={item.url} alt={`Recent pic ${index + 1}`} onClick={() => openModal(item.url)} />
              <p>{item.text && (item.text.length > 50 ? `${item.text.substring(0, 50)}...` : item.text)}</p>
              <button className="remove-button" onClick={() => removeImage(index)}>Remove</button>
            </div>
          )
        
        ))}
      </div>

      {showModal && (
        <div className="modal" onClick={closeModal}>
          <span className="close-modal">&times;</span>
          <img src={modalImage} alt="Full Size" />
          <button onClick={closeModal}>Close</button>
        </div>
      )}
    </div>
  );
}

export default ImageViewer;
