export const generateImage = async (inputText) => {
    try {
      const apiUrl = process.env.REACT_APP_API_IMAGE_URL;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.imageUrl; 
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  };
  
export const fetchBase64ByImageUrl = async (data) => {
  try {
    const response = await fetch('/.netlify/functions/image/fetchBase64ByImageUrl', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ imageUrl: data }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching Base64:', error);
    throw error;
  }
};
