import React from 'react';

const BookSelection = ({ 
      bookFilter, 
      handleBookFilterChange, 
      selectedBookId, 
      handleBookClick, 
      filteredBooks,
      showSelectBookHeader=true, 
      showBookFilterTextbox=true,
    }) => (
  <div className="books-section">
    {showSelectBookHeader && (
        <h3>Select a book of the bible</h3>
    )}

    <div>
      {showBookFilterTextbox && (
        <div></div>
        
        // hidden for now
        // <input 
        //     type="text"
        //     placeholder="Type to filter books e.g. Ezra"
        //     value={bookFilter}
        //     onChange={handleBookFilterChange}
        // />
      )}
      <select className="dropdown" value={selectedBookId} onChange={handleBookClick}>
          <option value="">Select a Book</option>
          {filteredBooks.map(book => (
          <option key={book.id} value={book.id}>{book.name}</option>
          ))}
      </select>
    </div>
  </div>
);

export default BookSelection;
