import React from 'react';

const VersesDisplay = ({ verses, selectedVerses, handleVerseSelection }) => (
  <div>
    {verses.length > 0 && selectedVerses.size === 0 && (
      <p style={{ color: 'red' }}>
         Select one or more verses below to view available actions
      </p>
    )}

    {verses.map(verse => (
      <div key={verse.id}>
        <input 
          type="checkbox" 
          checked={selectedVerses.has(verse.id)}
          onChange={() => handleVerseSelection(verse.id)} 
        />
        {verse.text}
      </div>
    ))}
  </div>
);

export default VersesDisplay;
