import React from 'react';

const ImageGenerator = ({ isGeneratingImage, generatedImageUrl }) => (
  <div>
    {isGeneratingImage ? (
      <div className="loader"></div>
    ) : generatedImageUrl ? (
      <img src={generatedImageUrl} alt="Generated" />
    ) : null}
  </div>
);

export default ImageGenerator;
