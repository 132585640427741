import React from 'react';

const Modal = ({ showModal, closeModal, modalImage, modalContent, contentType }) => (
  <div className='container-flexible-margin'>
    {showModal && (
      <div className="modal">
         <span className="close-modal" onClick={closeModal}>&times;</span>
        <div className="modal-content"> {/* Apply the new class here */}
          {contentType === 'image' && (
            <img src={modalImage} alt="Full Size" />
          )}
          {contentType === 'text' && (
            <div className="modal-text-content">
              {modalContent.split('\n\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          )}
          {/* <button onClick={closeModal}>Close</button> */}
        </div>
      </div>
    )}
  </div>
);

export default Modal;
