import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';

import './SideMenu.css';

function SideMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const sideMenuRef = useRef(); 
  let navigate = useNavigate(); 

  useEffect(() => {
    // Set user info from Netlify Identity
    setUser(netlifyIdentity.currentUser());

    // Listen for login and logout events to update user info
    const updateUserInfo = () => setUser(netlifyIdentity.currentUser());

    netlifyIdentity.on('login', updateUserInfo);
    netlifyIdentity.on('logout', updateUserInfo);

    const handleClickOutside = (event) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      netlifyIdentity.off('login', updateUserInfo);
      netlifyIdentity.off('logout', updateUserInfo);
    };
  }, [sideMenuRef]);

  // Logout function
  const handleLogout = () => {
    netlifyIdentity.logout();
    navigate('/'); 
  };

  return (
    <div className="side-menu-container">
      <button className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>☰</button>
      
      <div ref={sideMenuRef} className={`side-menu ${isMenuOpen ? 'open' : ''}`}>

        <div className="menu-section brand-logo">
          <ul className="menu-items">
            <li>
              <img src="/logo.png" alt="Brand Logo" style={{ maxWidth: '20px', maxHeight: '20px' }} />
              Knowing The Time
            </li>
          </ul>
        </div>

        {/* User greeting */}
        {user && (
          <div className="menu-section user-info">
            <ul className="menu-items">
              <li>Hello, {user.user_metadata.full_name || user.email}</li>
            </ul>
          </div>
        )}

        <div className="menu-section">
          <h3 className="menu-subhead">Navigation</h3>
          <ul className="menu-items">
            <li>
              <Link to="/">
                <i className="fas fa-home"></i> Dashboard
              </Link>
            </li>
          </ul>
        </div>

        <div className="menu-section">
          <h3 className="menu-subhead">AI Tools</h3>
          <ul className="menu-items">
            <li>
              <Link to="/dashboard/image-viewer">
                <i className="fas fa-image"></i> AI Image Generator
              </Link>
            </li>
            <li>
              <Link to="/dashboard/bible-verse-viewer">
                <i className="fas fa-bible"></i> Bible Verse Images
              </Link>
            </li>
            <li>
              <Link to="/dashboard/bible-study">
                <i className="fas fa-book-open"></i> 5D Bible Study
              </Link>
            </li>
            <li>
              <Link to="/dashboard/ai-search">
                <i className="fas fa-search"></i> AI Text Search
              </Link>
            </li>
          </ul>
        </div>

        {/* Open Tabs Section */}
        {/* ... */}

        {/* Logout Button */}
        <div className="menu-section logout-section">
          {user && (
            <button className="logout-button" onClick={handleLogout}>Logout</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
