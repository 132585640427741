import React from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';

function Dashboard() {
  const dashboardItems = [
    { 
      name: 'AI Image Generator', 
      description: 'Create images from textual descriptions using AI.', 
      icon: '🎨',
      link: '/dashboard/image-viewer'
    },
    { 
      name: 'Bible Verse Visualizer', 
      description: 'Visualize Bible verses with AI-generated imagery.', 
      icon: '📖',
      link: '/dashboard/bible-verse-viewer' 
    },
    { 
      name: 'Multidimensional Bible Study', 
      description: 'Explore the Bible in multiple dimensions for deeper understanding.', 
      icon: '🌐',
      link: '/dashboard/bible-study' 
    },
    { 
      name: 'AI Text Search', 
      description: 'Efficient search functionality powered by AI.', 
      icon: '🔍', 
      link: '/dashboard/ai-search' 
    },
    // { name: 'Wordfence Security', description: 'Firewall and security scanner.', icon: '🔒', link: '/dashboard/wordfence-security' },
    // { name: 'WPForms', description: 'Drag & drop form builder.', icon: '📝', link: '/dashboard/wpforms' },
    // { name: 'MonsterInsights', description: 'Google Analytics plugin.', icon: '📊', link: '/dashboard/monsterinsights' },
    // { name: 'WP Rocket', description: 'Caching and performance optimization.', icon: '🚀', link: '/dashboard/wp-rocket' }
  ];

  return (
    <div className="dashboard">
      {dashboardItems.map((item, index) => (
        <Link key={index} to={item.link} className="dashboard-item">
          <div className="icon">{item.icon}</div>
          <h3>{item.name}</h3>
          <p>{item.description}</p>
          <button className="dashboard-button">Let's Go</button>
        </Link>
      ))}
    </div>
  );
}

export default Dashboard;
