import React, { useState, useEffect } from 'react';
// import BackToDashboardLink from "../BackToDashboardLink";
import { handleChat } from '../../../api/chatApi';
import styles from './AISearch.module.css';

function AISearch() {
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);

    useEffect(() => {
        const history = localStorage.getItem('searchHistory');
        if (history) {
            setSearchHistory(JSON.parse(history));
        }
    }, []);

    useEffect(() => {
        // Only update localStorage if searchHistory is not empty, 
        // or if localStorage is indeed empty (to handle initial empty state)
        const history = localStorage.getItem('searchHistory');
        if (searchHistory.length > 0 || !history) {
            localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
        }
    }, [searchHistory]);
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const result = await handleChat({ query });
            setResponse(result.result);
            const newEntry = { query, response: result.result };
            setSearchHistory([...searchHistory, newEntry].slice(-5)); // Keep only the latest 5 entries
        } catch (error) {
            console.error('Error:', error);
            setResponse('Failed to fetch response.');
        }
        setIsLoading(false);
    };

    const handleRemoveHistoryItem = (index) => {
        const updatedHistory = searchHistory.filter((_, idx) => idx !== index);
        setSearchHistory(updatedHistory);
    };

    return (
        <div className={`container-flexible-margin ${styles.container}`}>
            {/* <BackToDashboardLink /> */}
            <div className={styles.content}>
                <h2>Simple AI Search</h2>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.searchBox}>
                        <textarea
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Enter your search term..."
                            className={styles.textArea}
                            rows={3}
                        />
                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className={styles.button}>
                            {isLoading ? <div className={styles.spinner}></div> : 'Search'}
                        </button>

                    </div>
                </form>
                {response && (
                    <div className={styles.response}>
                         <h3>Response:</h3>
                        {response.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                )}
                <div className={styles.historySection}>
                    <h3>Search History</h3>
                    <ul className={styles.historyList}>
                        {searchHistory.slice().reverse().map((item, index) => (
                            <li key={index} className={styles.historyItem}>
                                <p><strong>Query:</strong> {item.query}</p>
                                <div><strong>Response:</strong>
                                    {item.response.split('\n').map((line, lineIndex) => (
                                        <p key={lineIndex}>{line}</p>
                                    ))}
                                </div>
                                <button onClick={() => handleRemoveHistoryItem(index)}>remove</button>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default AISearch;
