// Fetch list of all Bible versions
export const fetchBibles = async () => {
  try {
    const response = await fetch('/.netlify/functions/bible/fetchBibles');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching Bibles:', error);
    throw error;
  }
};

// Fetch books of a specific Bible version
export const fetchBooks = async (bibleId) => {
  try {
    const response = await fetch(`/.netlify/functions/bible/fetchBooks?bibleId=${bibleId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching books for Bible ${bibleId}:`, error);
    throw error;
  }
};

// Fetch chapters of a specific book
export const fetchChapters = async (bibleId, bookId) => {
  try {
    const response = await fetch(`/.netlify/functions/bible/fetchChapters?bibleId=${bibleId}&bookId=${bookId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching chapters for book ${bookId}:`, error);
    throw error;
  }
};

// Fetch verses of a specific chapter
export const fetchVerses = async (bibleId, chapterId) => {
  try {
    const response = await fetch(`/.netlify/functions/bible/fetchVerses?bibleId=${bibleId}&chapterId=${chapterId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.map(verse => verse.id);
  } catch (error) {
    console.error(`Error fetching verses for chapter ${chapterId}:`, error);
    throw error;
  }
};

// Fetch all verses' content in HTML format
export const fetchAllVersesContent = async (bibleId, chapterId) => {
  try {
    const response = await fetch(`/.netlify/functions/bible/fetchAllVersesContent?bibleId=${bibleId}&chapterId=${chapterId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching content for chapter ${chapterId}:`, error);
    throw error;
  }
};

// Fetch a verse by ID
export const fetchVerseById = async (bibleId, verseId) => {
  try {
    const response = await fetch(`/.netlify/functions/bible/fetchVerseById?bibleId=${bibleId}&verseId=${verseId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching verse ${verseId}:`, error);
    throw error;
  }
};
