const handleApiRequest = async (apiUrl, data) => {
  try {
    if (!apiUrl) {
      throw new Error('API URL is not defined');
    }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const handleChat = async (data) => {
  const chatApiUrl = process.env.REACT_APP_API_CHAT_URL;
  return handleApiRequest(chatApiUrl, data);
};

export const handleChatAppendVerses = async (data) => {
  const chatApiUrl = process.env.REACT_APP_API_CHAT_URL + '?appendVerses=true';
  return handleApiRequest(chatApiUrl, data);
};

export const handle5DChat = async (data) => {
  const chat5DApiUrl = process.env.REACT_APP_API_CHAT_URL + '?use5DContext=true';
  return handleApiRequest(chat5DApiUrl, data);
};

export const handleGenerateChapterSummary = async (data) => {
  const chatApiUrl = process.env.REACT_APP_API_CHAT_URL + '?generateChapterSummary=true';
  return handleApiRequest(chatApiUrl, data);
};
