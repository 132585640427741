import React from 'react';

const ChapterSelection = ({ 
  selectedBookId, 
  selectedChapterId, 
  handleChapterChange, 
  chapters,
  showChapterSelectHeading
}) => (
  <div>
    {showChapterSelectHeading && (
        <h3>Select Chapter</h3>
    )}
    <select className="dropdown" value={selectedChapterId} onChange={handleChapterChange} disabled={!selectedBookId}>
      <option value="">Select Chapter</option>
      {chapters.map(chapter => (
        <option key={chapter.id} value={chapter.id}>{chapter.reference}</option>
      ))}
    </select>
  </div>
);

export default ChapterSelection;
