import React from 'react';

const RecentImages = ({ recentImages, openModal, removeImage }) => (
  <div className="recent-images">
    <h3>Recently Generated Images</h3>
    {recentImages.length === 0 ? (
            <div className="no-items-message">
              No history available.
            </div>
          ) : 
          (recentImages.map((item, index) => (
            <>
              <label style={{ color: 'red' }}><i>Important: Images will disappear after an hour, so be sure to save any you want to keep.</i></label><br />
              <div key={index} className="thumbnail">
                <img src={item.url} alt={`Recent pic: ${index + 1}`} onClick={() => openModal(item.url)} />
                <p>{item.text && (item.text.length > 50 ? `${item.text.substring(0, 50)}...` : item.text)}</p>
                <button className="remove-button" onClick={() => removeImage(index)}>Remove</button>
              </div>
            </>
          ))
    )}
  </div>
);

export default RecentImages;
