import React from 'react';
import { Outlet } from 'react-router-dom';
import SideMenu from './SideMenu';
import './MainLayout.css';

function MainLayout() {
  return (
    <div className="main-layout">
      <SideMenu />
      <div className="content-area">
        <Outlet /> {/* Nested routes will render here */}
      </div>
    </div>
  );
}

export default MainLayout;
